<template>
  <div class="my_help" v-bind:style="{ minHeight: Height + 'px' }">
    <!-- <div class="assist">
      <h2>帮助中心</h2>
      <el-tabs v-model="activeName" @tab-click="handleClick" class="help_item">
      
        <el-tab-pane label="使用指南" name="use">
          <el-collapse v-model="activeGuild" @change="handleGuild" accordion>
            <el-collapse-item title="智能咨询" name="1">
              <div>1.文献查找</div>
              <div>
                帮助用户查找各类文献资源，将需要查找的需求粘贴到输入框中，一行一个需求，同一需求的多个条件可用空格或分号分开。强烈推荐使用文献DOI、PMID、链接、ISBN号等唯一标识进行查找，以锁定需求文献。以下是需求输入示例：
              </div>
              <img
                src="../../../assets/imgs/user/new_personal/image_1.png"
                alt=""
              />

              <img
                src="../../../assets/imgs/user/new_personal/image_2.png"
                alt=""
              />
              <div>
                （1）“自动识别”模式：智能识别用户输入的文献需求，输入需求后一键发起查找，查询结果会以对话的形式显示在对话列表中。为方便查看和管理已查询的文献，查询结果会同步展示在“资源管理”列表中。
              </div>
              <img
                src="../../../assets/imgs/user/new_personal/image_3.png"
                alt=""
              />
              <div>
                （2）“手动调整”模式：对输入需求智能识别同时支持对识别结果进行编辑，确认无误后再提交查找，查询结果会展示在“资源管理”列表中。添加需求后点击“识别”，支持编辑识别结果；若需求类型识别错误，可手动修改。有特殊需求时，可在备注中说明。系统支持识别和检索参考文献篇名，但篇名可能重复。
              </div>
              <img
                src="../../../assets/imgs/user/new_personal/image_4.png"
                alt=""
              />
              <img
                src="../../../assets/imgs/user/new_personal/image_5.png"
                alt=""
              />
              <div>2.AI问答</div>
              <div>AI问答，帮助解答各类科研学术问题，助力科学研究与创新。</div>
              <img
                src="../../../assets/imgs/user/new_personal/image_6.png"
                alt=""
              />
            </el-collapse-item>
            <el-collapse-item title="资源发现" name="2">
              <div>
                平台集成了已购资源数据库，机构已开通的数据库会展示在页面左侧菜单栏，通过青梨证件审核的“正式用户”可以访问使用；
              </div>
              <img
                src="../../../assets/imgs/user/new_personal/image6.png"
                alt=""
              />
            </el-collapse-item>
            <el-collapse-item title="资源管理" name="3">
             

              <div>资源管理主要整合已查询的文献，方便用户查看和管理。</div>
              <img
                src="../../../assets/imgs/user/new_personal/image_7.png"
                alt=""
              />
              <div>1.列表操作</div>
              <div>
                ●
                下载链接：需求查找成功后状态会变为“处理完成”，同时会显示文件详情链接
                ，点击链接下载文献，部分文献支持在线浏览；
              </div>
              <img
                src="../../../assets/imgs/user/new_personal/image_8.png"
                alt=""
              />
              <div>
                ●
                需求详情：鼠标悬浮在需求名称处，可以看到该需求的详细信息；
              </div>
              <img
                src="../../../assets/imgs/user/new_personal/image_9.png"
                alt=""
              />
              <div>
                ●
                提交翻译：支持对外文文献一键翻译，翻译会消耗文档翻译的次数。任务处理完成后，可点击“查看结果“按钮查看翻译结果。
              </div>
              <img
                src="../../../assets/imgs/user/new_personal/image_10.png"
                alt=""
              />
              <div>
                ●
                批量操作：点击复选框可以批量导出或删除需求查找记录；
              </div>
              <img
                src="../../../assets/imgs/user/new_personal/image_11.png"
                alt=""
              />
              <div>
                ●
                更多操作：删除&错误反馈，删除需求查找记录，或对该条需求的查询结果进行错误反馈；
              </div>
              <div>
                ●
                二次处理：处理失败会转二次处理，需要的时间会久一点~请耐心等待；
              </div>
              <div>2.筛选功能</div>
              <div>
                ●
                支持对文献类型、需求状态、时间/来源等维度进行筛序；<br />
                ● 支持对历史文献进行检索；<br />
              </div>
              <img
                src="../../../assets/imgs/user/new_personal/image_12.png"
                alt=""
              />
            </el-collapse-item>
          </el-collapse>
        </el-tab-pane>
        <el-tab-pane label="常见问题" name="issuse">
          <el-collapse v-model="activeNames" @change="handleChange" accordion>
            <el-collapse-item
              title="1. 青梨支持的文献传递资源包括哪些？"
              name="1"
            >
              <div>
                青梨支持提供包括中外文期刊、图书、学位论文、标准、工具书、会议、技术报告等多种类型资源。
              </div>
            </el-collapse-item>
            <el-collapse-item title="2. 如何在青梨PC端提出需求？" name="2">
              <div>
                <span style="background: rgb(255, 230, 0)">强烈建议</span
                >您通过提交文献的DOI号、ISBN号、PMID号、URL地址等提交文献查找需求，以<span
                  style="background: rgb(255, 230, 0)"
                  >一行一个需求</span
                >的方式提交，<br />样例如下：
              </div>
              <img
                src="../../../assets/imgs/user/new_personal/image_1.png"
                alt=""
              />
              <div>不要采用以下方式提交</div>
              <img
                src="../../../assets/imgs/user/new_personal/image_2.png"
                alt=""
              />
            </el-collapse-item>
            <el-collapse-item title="3. 需求识别有误，如何编辑需求内容？" name="3">
              <div>
                如果同一条需求被识别为多条需求，需要您确认下是否存在多余的换行。<br />
                合并需求，勾选需要合并的需求，点击合并需求即可将所选需求合并为一条。<br />
                样例如下：
              </div>
              <img
                src="../../../assets/imgs/user/new_personal/image_13.png"
                alt=""
              /> <div>
                需求内容修改，点击编辑按钮，即可修改需求内容。样例如下：<br />
                样例如下：（ <span style="color:#F56C6C;">ISBN位数不对</span>）
              </div>
              <img
                src="../../../assets/imgs/user/new_personal/image_14.png"
                alt=""
              />
              <img
                src="../../../assets/imgs/user/new_personal/image_15.png"
                alt=""
              />
            </el-collapse-item>
            
            <el-collapse-item title="4. 怎么查看历史提交的文献" name="4">
              <div>
                在资源界面，可查看文献需求列表，支持文献类型、需求状态、时间/来源等维度进行筛序。
              </div>
              <img
                src="../../../assets/imgs/user/new_personal/image_16.png"
                alt=""
              />
            </el-collapse-item>
            <el-collapse-item title="5. 链接是否会过期？" name="5">
              <div>
                中文图书链接有效期为72小时，其他类型需求链接有效期为24小时。
              </div>
            </el-collapse-item>

            <el-collapse-item title="6. 是否可以看到提交的文献需求详情？" name="6">
              <div>
                鼠标悬浮在需求名称处，可以看到该需求的详细信息。
              </div>
              <img
                src="../../../assets/imgs/user/new_personal/image_17.png"
                alt=""
              />
            </el-collapse-item>
            <el-collapse-item
              title="7. 每天提出的文献需求是否有上限？"
              name="7"
            >
              <div>
                每天提交的文献需求存在上限，可在“我的”页面查看各类需求的上限数量。
              </div>
            
            </el-collapse-item>
          </el-collapse>
        </el-tab-pane>
      </el-tabs>
    </div> -->
    <div class="new_help_css">
      <div class="new_help_top">
        <span class="new_help_tit">使用指南及常见问题指南</span>
      </div>
      <div class="new_help_box">
        <div class="box_left">
          <div class="left_">
            <div class="left_tit">使用指南</div>
            <div class="left_li_s">
              <div
                class="left_li"
                v-for="(item, index) in guide_s"
                :key="index"
                :class="liActive == item.val ? 'left_li_active' : ''"
                @click="changActive(item)"
              >
                {{ item.lab }}
              </div>
            </div>
          </div>
          <div class="left_">
            <div class="left_tit">常见问题</div>
            <div class="left_li_s">
              <div
                class="left_li"
                v-for="(item, index) in issue_s"
                :key="index"
                :class="liActive == item.val ? 'left_li_active' : ''"
                @click="changActive(item)"
              >
                {{ item.lab }}
              </div>
            </div>
          </div>
        </div>
        <div class="box_line"></div>
        <div class="box_right">
          <div class="right_tit">
            {{ title }}
          </div>
          <div class="right_line"></div>
          <div class="right_con">
            <div class="con_box" v-if="liActive == '1_1'">
              <div class="con_tit">1.文献查找</div>
              <div class="con_txt">
                帮助用户查找各类文献资源，将需要查找的需求粘贴到输入框中，一行一个需求，同一需求的多个条件可用空格或分号分开。强烈推荐使用文献DOI、PMID、链接、ISBN号等唯一标识进行查找，以锁定需求文献。以下是需求输入示例：
              </div>
              <img
                src="../../../assets/imgs/user/new_personal/image_1.png"
                alt=""
              />

              <img
                src="../../../assets/imgs/user/new_personal/image_2.png"
                alt=""
              />
              <div class="con_txt">
                （1）“自动识别”模式：智能识别用户输入的文献需求，输入需求后一键发起查找，查询结果会以对话的形式显示在对话列表中。为方便查看和管理已查询的文献，查询结果会同步展示在“资源管理”列表中。
              </div>
              <img
                src="../../../assets/imgs/user/new_personal/image_3.png"
                alt=""
              />
              <div class="con_txt">
                （2）“手动调整”模式：对输入需求智能识别同时支持对识别结果进行编辑，确认无误后再提交查找，查询结果会展示在“资源管理”列表中。添加需求后点击“识别”，支持编辑识别结果；若需求类型识别错误，可手动修改。有特殊需求时，可在备注中说明。系统支持识别和检索参考文献篇名，但篇名可能重复。
              </div>
              <img
                src="../../../assets/imgs/user/new_personal/image_4.png"
                alt=""
              />
              <img
                src="../../../assets/imgs/user/new_personal/image_5.png"
                alt=""
              />
              <div class="con_tit">2.AI问答</div>
              <div class="con_txt">
                AI问答，帮助解答各类科研学术问题，助力科学研究与创新。
              </div>
              <img
                src="../../../assets/imgs/user/new_personal/image_6.png"
                alt=""
              />
            </div>
            <div class="con_box" v-if="liActive == '1_2'">
              <div class="con_txt">
                平台集成了已购资源数据库，机构已开通的数据库会展示在页面左侧菜单栏，通过青梨证件审核的“正式用户”可以访问使用；
              </div>
              <img
                src="../../../assets/imgs/user/new_personal/image6.png"
                alt=""
              />
            </div>
            <div class="con_box" v-if="liActive == '1_3'">
              <div class="con_txt">
                资源管理主要整合已查询的文献，方便用户查看和管理。
              </div>
              <img
                src="../../../assets/imgs/user/new_personal/image_7.png"
                alt=""
              />
              <div class="con_tit">1.列表操作</div>
              <div class="con_txt">
                ●
                下载链接：需求查找成功后状态会变为“处理完成”，同时会显示文件详情链接
                ，点击链接下载文献，部分文献支持在线浏览；
              </div>
              <img
                src="../../../assets/imgs/user/new_personal/image_8.png"
                alt=""
              />
              <div class="con_txt">
                ● 需求详情：鼠标悬浮在需求名称处，可以看到该需求的详细信息；
              </div>
              <img
                src="../../../assets/imgs/user/new_personal/image_9.png"
                alt=""
              />
              <div class="con_txt">
                ●
                提交翻译：支持对外文文献一键翻译，翻译会消耗文档翻译的次数。任务处理完成后，可点击“查看结果“按钮查看翻译结果。
              </div>
              <img
                src="../../../assets/imgs/user/new_personal/image_10.png"
                alt=""
              />
              <div class="con_txt">
                ● 批量操作：点击复选框可以批量导出或删除需求查找记录；
              </div>
              <img
                src="../../../assets/imgs/user/new_personal/image_11.png"
                alt=""
              />
              <div class="con_txt">
                ●
                更多操作：删除&错误反馈，删除需求查找记录，或对该条需求的查询结果进行错误反馈；
              </div>
              <div class="con_txt">
                ●
                二次处理：处理失败会转二次处理，需要的时间会久一点~请耐心等待；
              </div>
              <div class="con_tit">2.筛选功能</div>
              <div class="con_txt">
                ● 支持对文献类型、需求状态、时间/来源等维度进行筛序；<br />
                ● 支持对历史文献进行检索；<br />
              </div>
              <img
                src="../../../assets/imgs/user/new_personal/image_12.png"
                alt=""
              />
            </div>
            <div class="con_box" v-if="liActive == '2_1'">
              <div class="con_txt">
                青梨支持提供包括中外文期刊、图书、学位论文、标准、工具书、会议、技术报告等多种类型资源。
              </div>
            </div>
            <div class="con_box" v-if="liActive == '2_2'">
              <div class="con_txt">
                <span style="background: rgb(255, 230, 0)">强烈建议</span
                >您通过提交文献的DOI号、ISBN号、PMID号、URL地址等提交文献查找需求，以<span
                  style="background: rgb(255, 230, 0)"
                  >一行一个需求</span
                >的方式提交，<br />样例如下：
              </div>
              <img
                src="../../../assets/imgs/user/new_personal/image_1.png"
                alt=""
              />
              <div class="con_txt">不要采用以下方式提交</div>
              <img
                src="../../../assets/imgs/user/new_personal/image_2.png"
                alt=""
              />
            </div>
            <div class="con_box" v-if="liActive == '2_3'">
              <div class="con_txt">
                如果同一条需求被识别为多条需求，需要您确认下是否存在多余的换行。<br />
                合并需求，勾选需要合并的需求，点击合并需求即可将所选需求合并为一条。<br />
                样例如下：
              </div>
              <img
                src="../../../assets/imgs/user/new_personal/image_13.png"
                alt=""
              />
              <div class="con_txt">
                需求内容修改，点击编辑按钮，即可修改需求内容。样例如下：<br />
                样例如下：（ <span style="color: #f56c6c">ISBN位数不对</span>）
              </div>
              <img
                src="../../../assets/imgs/user/new_personal/image_14.png"
                alt=""
              />
              <img
                src="../../../assets/imgs/user/new_personal/image_15.png"
                alt=""
              />
            </div>
            <div class="con_box" v-if="liActive == '2_4'">
              <div class="con_txt">
                在资源界面，可查看文献需求列表，支持文献类型、需求状态、时间/来源等维度进行筛序。
              </div>
              <img
                src="../../../assets/imgs/user/new_personal/image_16.png"
                alt=""
              />
            </div>
            <div class="con_box" v-if="liActive == '2_5'">
              <div class="con_txt">
                中文图书链接有效期为72小时，其他类型需求链接有效期为24小时。
              </div>
            </div>
            <div class="con_box" v-if="liActive == '2_6'">
              <div class="con_txt">
                鼠标悬浮在需求名称处，可以看到该需求的详细信息。
              </div>
              <img
                src="../../../assets/imgs/user/new_personal/image_17.png"
                alt=""
              />
            </div>
            <div class="con_box" v-if="liActive == '2_7'">
              <div class="con_txt">
                每天提交的文献需求存在上限，可在“我的”页面查看各类需求的上限数量。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "issuse",
    },
  },
  data() {
    return {
      activeName: this.name,
      activeNames: [""],
      activeGuild: [""],
      Height: 0,
      title: "智能咨询",
      liActive: "1_1",
      guide_s: [
        { val: "1_1", lab: "智能咨询" },
        { val: "1_2", lab: "资源发现" },
        { val: "1_3", lab: "资源管理" },
      ],
      issue_s: [
        { val: "2_1", lab: "青梨支持的文献传递资源包括哪些？" },
        { val: "2_2", lab: "如何在青梨PC端提出需求？" },
        { val: "2_3", lab: "需求识别有误，如何编辑需求内容？" },
        { val: "2_4", lab: "怎么查看历史提交的文献？" },
        { val: "2_5", lab: "链接是否会过期？" },
        { val: "2_6", lab: "是否可以看到提交的文献需求详情？" },
        { val: "2_7", lab: "每天提出的文献需求是否有上限？" },
        // { val: "2_8", lab: "" },
      ],
      // Guild
    };
  },
  mounted() {
    console.log(this.name)
    if(this.name == 'issuse'){
      this.title =  "青梨支持的文献传递资源包括哪些？"
      this.liActive = "2_1"
    }
    //动态设置内容高度 让footer始终居底   header+footer的高度是190
    // console.log(this.name)
    this.Height = document.documentElement.clientHeight - 120; //监听浏览器窗口变化
    window.onresize = () => {
      this.Height = document.documentElement.clientHeight - 120;
    };
  },
  methods: {
    changActive(row) {
      this.title = row.lab;
      this.liActive = row.val;
    },
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    handleChange(val) {
      // console.log(val);
    },
    handleGuild(val) {
      // console.log(val);
    },
  },
};
</script>

<style lang="less" scoped>
.my_help {
  // margin: 20px;
  // min-width: 901px;

  background-color: #fff;
  display: flex;
  justify-content: center;
  // padding: 0 20%;
  .assist {
    // padding: 0 20px;
    // margin: 10px 0;
    width: 901px;
    h2 {
      height: 50px;
      line-height: 50px;
      // padding-left: 5px;
    }
    .question {
      li {
        height: 50px;
        line-height: 50px;
      }
    }
  }
}
.help_item {
  // padding: 0 20px;
  // width: 50%;
  .el-tab-pane {
    width: 100%;
    .el-collapse {
      width: 100%;
    }
  }
  div {
    // text-indent:2em;
  }
  img {
    width: 100%;
  }
  /deep/ .el-tabs__content {
    min-height: 300px;
  }
  .is-active {
    color: #b14a75;
  }
}
/deep/ .el-tabs__item.is-active {
  color: #b14a75;
}
/deep/ .el-tabs__active-bar {
  background-image: linear-gradient(to bottom, #b14a75, #b14a75);
}
.a {
  background: rgb(255, 208, 0);
}
.new_help_css {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  .new_help_top {
    width: 100%;
    display: flex;
    height: 232px;
    padding: 98px 0;
    text-align: center;
    align-items: center;
    justify-content: center;
    gap: 4px;
    align-self: stretch;
    color: #1b84da;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    background-image: url("../../../assets/imgs/2.2/hellorf_help.png");
    background-size: cover;
  }
}
.new_help_box {
  display: flex;
  // height: 680px;
  padding: 18px 16px;
  align-items: flex-start;
  gap: 22px;
  align-self: stretch;
  .box_left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    .left_ {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      .left_tit {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
      .left_li_s {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 255px;
        .left_li {
          width: 100%;
          display: flex;
          height: 44px;
          padding: 0px 12px 0px 16px;
          align-items: center;
          gap: 4px;
          // flex: 1 0 0;
          border-radius: 0px 8px 8px 0px;
          border-left: 3px solid #fff;
          cursor: pointer;
        }
        .left_li:hover {
          background: #f5f5f5;
          border-left: 3px solid #e2426b;
        }
        .left_li_active {
          background: #f5f5f5;
          border-left: 3px solid #e2426b;
        }
      }
    }
  }
}
.box_line {
  width: 1px;
  background: var(---lv2, #e4e4e6);
}
.box_right {
  display: flex;
  padding: 16px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
  flex: 1 0 0;
  width: calc(100% - 300px);
  img {
    width: 50%;
    margin-right: 50%;
  }
  .right_tit {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    color: #000;
    font-size: 32px;
  }
  .right_line {
    height: 1px;
    background: var(---, #8d8f94);
  }
}
.right_con::-webkit-scrollbar {
  display: none;
}
.right_con {
  height: calc(100vh - 500px);
  overflow-x: hidden;
  overflow-y: scroll;
}
.con_tit {
  color: var(---, #262729);
  font-weight: 500;
  line-height: normal;
}
.con_box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.con_txt {
  color: var(---, #6b6e73);
  line-height: 20px;
}
</style>