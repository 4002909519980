<template>
  <div class="headers">
    <div class="my_header">
      <div class="message" style="gap: 25px">
        <div class="tit_h2">
          <h2>账户信息</h2>
          <span
            v-if="
              msg_copy.is_bind_wx && msg_copy.sing_on && !msg_copy.is_perfect
            "
            @click="editUser"
          >
            微信绑定 <i class="el-icon-edit-outline"></i>
          </span>
        </div>
        <!--  -->
        <div
          style="
            display: flex;
            padding: 0px 24px;
            align-items: flex-start;
            gap: 100px;
            align-self: stretch;
          "
        >
          <img
            v-if="
              headshot &&
              headshot !==
                'https://thirdwx.qlogo.cn/mmopen/vi_32/POgEwh4mIHO4nibH0KlMECNjjGxQUq24ZEaGT4poC6icRiccVGKSyXwibcPq4BWmiaIGuG1icwxaQX6grC9VemZoJ8rg/132'
            "
            :src="headshot"
            alt=""
          />
          <img v-else src="../../../assets/imgs/2.2/user_2x_02.png" alt="" />
          <div class="meaasge_box">
            <div class="box_tit">系统信息</div>
            <div class="box_li">
              <div class="box_li_left">姓名</div>
              <div class="box_li_right">
                {{ name }}
              </div>
            </div>
            <div class="box_li">
              <div class="box_li_left">认证状态</div>
              <div class="box_li_right">
                <div
                  class="accountType"
                  v-if="
                    (msg_copy.id_photo && msg_copy.id_photo_state == 2) ||
                    !msg_copy.is_idphoto
                  "
                >
                  正式用户
                </div>

                <div type="warning" class="accountType" v-else>临时用户</div>
              </div>
            </div>
            <div class="box_li">
              <div class="box_li_left">所属机构</div>
              <div class="box_li_right">
                {{ orgName ? orgName : "--" }}
              </div>
            </div>
            <div class="box_li">
              <div class="box_li_left">邮箱</div>
              <div class="box_li_right">
                {{ email_(email) ? email_(email) : "--" }}
              </div>
            </div>
            <div class="box_li">
              <div class="box_li_left">电话</div>
              <div class="box_li_right">
                {{ phone ? phone : "--" }}
              </div>
            </div>
            <!-- <div class="tag">
              <span class="name">{{ name }}</span>
              <el-tag
                effect="dark"
                class="accountType"
                v-if="
                  (msg_copy.id_photo && msg_copy.id_photo_state == 2) ||
                  !msg_copy.is_idphoto
                "
                >正式用户</el-tag
              >
              
              <el-tag effect="dark" type="warning" class="accountType" v-else
                >临时用户</el-tag
              >
              <span v-if="status == 2 || status == 4" @click="textmsg">
                <i
                  class="el-icon-question"
                  style="font-size: 20px; cursor: pointer"
                ></i>
              </span>
              <el-tag class="groupName" v-if="source_name !== ''"
                ><el-tooltip
                  class="item"
                  effect="dark"
                  :content="source_name"
                  placement="bottom"
                  ><span>{{ source_name }}</span></el-tooltip
                ></el-tag
              >
            </div>
            <div class="email">邮箱：{{ email_(email) }}</div>
            <div class="phone">电话：{{ phone }}</div> -->
          </div>
        </div>
      </div>
      <div class="message" style="gap: 15px">
        <h2>文献信息</h2>
        <!-- <div class="table_css">
          <div class="table_header_s">

          </div>
          <div class="table_th_s"></div>
          <div class="table_th_s"></div>
        </div> -->
        <el-table :data="tableData" stripe border class="literatureInfo">
          <el-table-column prop="type" align="center" label="类型">
            <template slot-scope="scope">
              <span class="table_type">
                {{ scope.row.type }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="中文书" align="center" label="中文书">
            <template slot-scope="scope">
              <span class="table_type">
                {{ scope.row["中文书"] }} 册
              </span></template
            >
          </el-table-column>
          <el-table-column prop="英文书" align="center" label="英文书">
            <template slot-scope="scope">
              <span class="table_type">
                {{ scope.row["英文书"] }} 册
              </span></template
            >
          </el-table-column>
          <el-table-column prop="中文文献" align="center" label="中文文献">
            <template slot-scope="scope">
              <span class="table_type">
                {{ scope.row["中文文献"] }} 篇
              </span></template
            >
          </el-table-column>
          <el-table-column prop="英文文献" align="center" label="英文文献">
            <template slot-scope="scope">
              <span class="table_type">
                {{ scope.row["英文文献"] }} 篇
              </span></template
            >
          </el-table-column>
          <el-table-column prop="其他" align="center" label="其他">
            <template slot-scope="scope">
              <span class="table_type">
                {{ scope.row["其他"] }} 篇
              </span></template
            >
          </el-table-column>
        </el-table>
      </div>
      <div class="message" style="gap: 15px">
        <h2>资源使用情况</h2>

        <el-table :data="useData" stripe border class="literatureInfo">
          <el-table-column prop="type" align="center" label="类型">
            <template slot-scope="scope">
              <span class="table_type">
                {{ scope.row.type }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            v-for="(value, key, index) in useData[0]"
            :key="index"
            :prop="key"
            align="center"
            :label="key == 'type' ? '类型' : key"
            v-if="key !== 'type'"
          >
            <template slot-scope="scope">
              <span class="table_type">
                {{ scope.row[key] }}<span v-if="key == '图书/天'"> 册</span>
                <span v-else-if="key !== 'type'"> 篇</span>
              </span>
            </template>
          </el-table-column>
          <!-- 
          <el-table-column prop="图书/天" align="center" label="图书/天">
            <template slot-scope="scope">
              <span class="table_type">
                {{ scope.row["图书/天"] }} 册
              </span></template
            >
          </el-table-column>
          <el-table-column prop="文献/天" align="center" label="文献/天">
            <template slot-scope="scope">
              <span class="table_type">
                {{ scope.row["文献/天"] }} 篇
              </span></template
            >
          </el-table-column>
          <el-table-column prop="校对/年" align="center" label="校对/年">
            <template slot-scope="scope">
              <span class="table_type">
                {{ scope.row["校对/年"] }} 篇
              </span></template
            >
          </el-table-column>
          <el-table-column prop="查重/总量" align="center" label="查重/总量">
            <template slot-scope="scope">
              <span class="table_type">
                {{ scope.row["查重/总量"] }} 篇
              </span></template
            >
          </el-table-column>
          <el-table-column prop="翻译/总量" align="center" label="翻译/总量">
            <template slot-scope="scope">
              <span class="table_type">
                {{ scope.row["翻译/总量"] }} 篇
              </span></template
            >
          </el-table-column> -->
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { personalMessage, wenNumber } from "@/Api/userMessage";
export default {
  data() {
    return {
      name: "",
      id_photo: false,
      source_name: "",
      email: "",
      phone: "",
      tableData: [],
      useData: [],
      headshot: "",
      status: localStorage.getItem("status"),
      msg_copy: {
        sing_on: false,
        is_perfect: false,
      },
      path_: "",
      keys_: "",
      orgName: "",
    };
  },
  created() {
    // this.created_fun()
    this.created_fun();
  },
  watch: {
    $route(to, from) {
      if (to.query.webPage) {
        // console.log("to.query.webPage",to, to.query.webPage);
        if (to.name == "Personal" && to.query.webPage == "1") {
          this.created_fun();
        }
      }
    },
  },
  mounted() {},
  methods: {
    created_fun() {
      if (this.$route.path) {
        this.path_ = this.$route.path;
      }
      if (this.$route.query.webPage) {
        this.keys_ = this.$route.query.webPage.toString();
      }
      console.log(this.path_, this.keys_);
      if (sessionStorage.getItem("headshot")) {
        this.headshot = sessionStorage.getItem("headshot");
      }
      if (sessionStorage.getItem("mes")) {
        let mes_ = JSON.parse(sessionStorage.getItem("mes"));
        this.name = mes_.name;
        this.id_photo = mes_.id_photo;
        this.source_name = mes_.belong_name;
        this.email = mes_.email;
        this.phone = mes_.phone;
        this.headshot = mes_.avatarUrl;
        this.orgName = mes_.org_name;
        sessionStorage.setItem("headshot", mes_.avatarUrl);
        sessionStorage.setItem("unionid", mes_.unionid);
        this.msg_copy = mes_;

        if (
          this.msg_copy.sing_on &&
          this.msg_copy.is_perfect &&
          this.path_ == "/personal" &&
          this.keys_ == "1"
        ) {
          this.$bus.$emit("callFunction", this.msg_copy);
        }
        this.getAgeCount();
      } else {
        this.message();
      }
    },
    textmsg() {
      // 判断status
      if (this.status == 2) {
        this.$message({
          message:
            "您当前的账号状态，为了更精确的确认您的身份信息，请您完善您的个人信息与证件",
          type: "warning",
        });
      } else if (this.status == 4) {
        this.$message({
          message: "账号审核未通过，账号信息不符合规则，您可以修改账号信息",
          type: "warning",
        });
      }
    },
    message() {
      personalMessage()
        .then((res) => {
          console.log(res);
          this.name = res.data.data.name;
          this.id_photo = res.data.data.id_photo;
          this.source_name = res.data.data.belong_name;
          this.email = res.data.data.email;
          this.phone = res.data.data.phone;
          this.orgName = res.data.data.org_name;
          this.headshot = res.data.data.avatarUrl;
          this.msg_copy = res.data.data;
          sessionStorage.setItem("headshot", res.data.data.avatarUrl);
          sessionStorage.setItem("unionid", res.data.data.unionid);
          if (
            this.msg_copy.sing_on &&
            this.msg_copy.is_perfect &&
            this.path_ == "/personal" &&
            this.keys_ == "1"
          ) {
            this.$bus.$emit("callFunction", this.msg_copy);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      this.getAgeCount();
    },
    getAgeCount() {
      wenNumber()
        .then((res) => {
          let settingObj = res.data.data;
          let settingArr = Object.keys(settingObj).map((key) => {
            if (key == "year") {
              settingObj["year"].type = "本年";
            } else if (key == "day") {
              settingObj["day"].type = "本日";
            } else if (key == "remaining") {
              settingObj["remaining"].type = "剩余";
            } else if (key == "total") {
              settingObj["total"].type = "总量";
            }
            return settingObj[key];
            //把每个对象返回出去生成一个新的数组中相当于0:{id:1}
          });
          // console.log(JSON.stringify(settingArr))
          const filteredData = settingArr.filter(
            (item) => item.type === "本年" || item.type === "本日"
          );

          // console.log(filteredData);
          this.tableData = filteredData;
          const filteredData_ = settingArr.filter(
            (item) => item.type === "剩余" || item.type === "总量"
          );
          this.useData = filteredData_;
          // console.log("aaaa", JSON.stringify(this.useData));
        })
        .catch((err) => {
          console.log(err);
        });
    },
    editUser() {
      let mes_ = JSON.parse(sessionStorage.getItem("mes"));
      this.msg_copy = mes_;
      this.$bus.$emit("callFunction", this.msg_copy);
    },
    email_(email) {
      let reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (!reg.test(email)) {
        return "";
      } else {
        return email;
      }
    },
  },
};
</script>

<style lang='less' scoped>
.headers {
  // margin: 20px;

  // background-color: #ffffff;
  // display: flex;
  // justify-content: center;
  // padding: 0 20px;
  // min-width: 986px;
  .my_header {
    // display: flex;
    // justify-content: center;
    // min-width: 880px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    .message {
      // min-width: 450px;
      // width: 45%;
      // margin-right: 20px;
      display: flex;
      padding: 18px 16px;
      flex-direction: column;
      align-self: stretch;
      align-items: flex-start;
      width: 100%;
      border-radius: 8px;
      background: #fff;
      .literatureInfo {
        width: 100%;
        :nth-child(1) {
          // font-weight: 600;
          color: var(--Gray-Gray-500, #a1a5b7);
          font-weight: 500;
        }
      }
      img {
        margin: 20px;
        width: 120px;
        // width: 40%;
        // max-width: 120px;
        height: 120px;
        border-radius: 60px;
      }

      .meaasge_box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 14px;
        flex: 1 0 0;
        .box_tit {
          color: var(---, #8d8f94);
          font-weight: 500;
          line-height: 20px;
        }
        .box_li {
          display: flex;
          height: 32px;
          justify-content: space-between;
          align-items: center;
          .box_li_left {
            width: 180px;
            color: var(--Gray-500, #79716b);
          }
        }
        .accountType {
          // background-color: #74d8ca;
          // color: #fff;
          // border-radius: 0;
          display: flex;
          padding: 5px 10px;
          justify-content: center;
          align-items: center;
          gap: 4px;
          border-radius: 4px;
          background: #e2426b;
          color: var(---Color-7, #fff);
          font-size: 10px;
        }
        .tag {
          display: flex;
          align-items: center;
          span {
            // display: inline-block;
          }
          .name {
            font-size: 16px;
            font-weight: 600;
            min-width: 48px;
            margin: 0 10px 0 0;
            max-width: 140px;
            overflow: hidden;
            -webkit-line-clamp: 2;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
          }

          .groupName {
            background-color: #72b3d9;
            color: #fff;
            margin: 0 10px;
            // border-radius: 0;
            span {
              display: inline-block;
              width: 60px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
        div {
          // margin: 10px 0;
        }
      }
    }
  }
}
.tit_h2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    color: #e2426b;
    font-size: 16px;
    cursor: pointer;
  }
}
.table_type {
  color: var(--Gray-Gray-800, #3f4254) !important;
}
</style>